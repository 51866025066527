import React, {useEffect, useState, Fragment} from "react";
import {connect, useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom';
import {withTranslation} from "react-i18next";
import {
    Badge, Button,
} from "reactstrap"
import {showRightSidebarUpdateParcelAction} from "../../store/layout/actions";
import {getProduct} from "../../store/products/actions";
import {isEmpty} from "lodash";

import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import {paymentFlightId} from "../../store/payment/actions";
import {BranchAddress} from "../../helpers/types";

const TableObject = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleUpdateParcel = (id) => {
        let item = {
            id
        }
        dispatch(getProduct(item))
        dispatch(showRightSidebarUpdateParcelAction(true))
    }

    const handleLink = (filename) => {
        if (filename) {
            window.open(process.env.REACT_APP_UPLOADER_SERVER_URL + '/uploads/invoices/' + filename)
        }
    }

    const handlePayFlight = (it) => {
        let item = {
            flightId: it
        }
        dispatch(paymentFlightId(item))
    }

    return (
        <>
            {
                props.status === 'obtained'
                    ?
                    <>
                        {
                            !isEmpty(props.Products.giveawayFlightObtainet)
                                ?
                                <>
                                    {
                                        props.Products.giveawayFlightObtainet
                                            .filter((todo) => (todo.flightId.includes(props.flightCountry)))
                                            .map((it, k) => {

                                                return (
                                                    <Fragment key={k}>
                                                        {
                                                            props.status !== 'sent'
                                                                ? <td colSpan={8} style={{padding: '0px'}}>
                                                                    <div style={{float: 'left', padding: '0px 0px 0px 10px'}}>
                                                                        {
                                                                            it.state === 'paid'
                                                                                ? <Badge
                                                                                    className={`badge bg-pill font-size-12 bg-soft-success`}
                                                                                    pill
                                                                                >
                                                                                    <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}  - {it.flightId}
                                                                                    </h6>
                                                                                    {props.t("_PAID_")}
                                                                                </Badge>
                                                                                : <Badge
                                                                                    className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                                    pill
                                                                                >
                                                                                    <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}  - {it.flightId}
                                                                                    </h6>
                                                                                    {props.t("_UNPAID_")}
                                                                                </Badge>
                                                                        }

                                                                    </div>
                                                                    <div style={{float: 'left', padding: '0px 0px 0px 10px '}}>
                                                                        <Button
                                                                            onClick={() => history.push(`/invoice/${it.flightId}`)}
                                                                            type="button" color="primary"
                                                                            style={{width: '100%', height: '34px'}}
                                                                            className="btn-sm waves-effect waves-light">
                                                                            {props.t("_INVOICE_")}
                                                                        </Button>
                                                                    </div>
                                                                    {
                                                                        it.state !== 'paid'
                                                                            ?
                                                                            <div style={{float: 'left', padding: '0px 0px 0px 10px '}}>
                                                                                <Button
                                                                                    onClick={handlePayFlight.bind(this, it.flightId)}
                                                                                    type="button" color="success"
                                                                                    style={{width: '100%', height: '34px'}}
                                                                                    className="btn-sm waves-effect waves-light">
                                                                                    {props.t("_PAY_")}
                                                                                </Button>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </td>
                                                                :
                                                                <td colSpan={8} style={{padding: '0px'}}>
                                                                    <div style={{float: 'left', padding: '0px 0px 0px 10px'}}>
                                                                        <Badge
                                                                            className={`badge bg-pill font-size-12 bg-soft-primary`}
                                                                            pill
                                                                        >
                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}  - {it.flightId}
                                                                            </h6>
                                                                        </Badge>
                                                                    </div>
                                                                </td>

                                                        }


                                                        {
                                                            props.Products.arrayObtainet.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            props.country === item.senderCountryId && item.flightId === it.flightId
                                                                                ?
                                                                                <tr
                                                                                    title={item.custom === true || item.clearance === true ? props.t('_PARCEL_CUSTOM_CLERANCE_') : null}
                                                                                    style={item.custom === true || item.clearance === true ? {backgroundColor: "#fff2f2"} : {}}>
                                                                                    <td>{item.productOrderId}  </td>
                                                                                    <td>{props.t(item.productName)} </td>
                                                                                    <td>
                                                                                        {item.quantity}
                                                                                    </td>

                                                                                    <td>
                                                                                        {item.shop}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.price}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.currency}
                                                                                    </td>
                                                                                    <td>

                                                                                        {
                                                                                            item.file
                                                                                                ?
                                                                                                <Button
                                                                                                    onClick={handleLink.bind(this, item.file)}
                                                                                                    type="button" color="success"
                                                                                                    style={{width: '100%'}}
                                                                                                    className="btn-sm waves-effect waves-light">
                                                                                                    {props.t("_FILE_")}
                                                                                                </Button>
                                                                                                : null
                                                                                        }

                                                                                        {
                                                                                            item.description
                                                                                                ?
                                                                                                <>
                                                                                                    <div
                                                                                                        style={{paddingTop: '5px'}}>
                                                                                                        <p data-tip={item.description}>
                                                                                                            <Button type="button"
                                                                                                                    color="primary"
                                                                                                                    style={{width: '100%'}}
                                                                                                                    className="btn-sm waves-effect waves-light">
                                                                                                                {props.t("_COMMENT_")}
                                                                                                            </Button>
                                                                                                        </p>
                                                                                                        <ReactTooltip/>
                                                                                                    </div>
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                    </td>
                                                                                    <td>

                                                                                        {
                                                                                            props.status === 'arrived' || props.status === 'obtained'
                                                                                                ? null
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        it.updateStatus
                                                                                                            ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                    type="button"
                                                                                                                    // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                    color={
                                                                                                                        it.updateStatus
                                                                                                                            ? item.quantity ? 'warning' : 'danger'
                                                                                                                            : 'danger'
                                                                                                                    }
                                                                                                                    className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                    {
                                                                                                                        it.updateStatus
                                                                                                                            ? <>
                                                                                                                                {
                                                                                                                                    item.quantity
                                                                                                                                        ? <> {props.t("_EDIT_")}</>
                                                                                                                                        : <> {props.t("_DECLARE_")}</>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            : null
                                                                                                                    }
                                                                                                                </Button>

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.quantity && item.price && item.quantity
                                                                                                                        ? null
                                                                                                                        :
                                                                                                                        <Button
                                                                                                                            onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                            type="button"
                                                                                                                            // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                            color={
                                                                                                                                it.updateStatus
                                                                                                                                    ? item.quantity ? 'warning' : 'danger'
                                                                                                                                    : 'danger'
                                                                                                                            }
                                                                                                                            className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                            {props.t("_DECLARE_")}
                                                                                                                        </Button>
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                        }

                                                                                    </td>
                                                                                </tr>
                                                                                : null

                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }

                                                    </Fragment>
                                                )
                                            })
                                    }
                                </>
                                : null

                        }
                    </>
                    :
                    <>
                        {

                            !isEmpty(props.Products.giveawayFlight)
                                ?
                                <>

                                    {
                                        props.Products.giveawayFlight
                                            .filter((todo) => (todo.flightId.includes(props.flightCountry)))
                                            .map((it, k) => {
                                                const hasUnpaid = props.Products.array.some(product => product.state === "unpaid");
                                                return (
                                                    <Fragment key={k}>
                                                        {
                                                            props.status !== 'sent'
                                                                ?
                                                                <>
                                                                    <tr colSpan={8} style={{padding: '0px'}}>

                                                                        <div style={{float: 'left', padding: '0px 0px 0px 10px'}}>
                                                                            {
                                                                                it.state === 'paid' && !hasUnpaid
                                                                                    ? <Badge
                                                                                        className={`badge bg-pill font-size-12 bg-soft-success`}
                                                                                        pill
                                                                                    >
                                                                                        <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")} - {it.flightId}
                                                                                        </h6>
                                                                                        {props.t("_PAID_")}
                                                                                    </Badge>
                                                                                    : <Badge
                                                                                        className={`badge bg-pill font-size-12 bg-soft-warning`}
                                                                                        pill
                                                                                    >
                                                                                        <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")} - {it.flightId}
                                                                                        </h6>
                                                                                        {props.t("_UNPAID_")}
                                                                                    </Badge>
                                                                            }

                                                                        </div>
                                                                        {
                                                                            it.tbilisi  &&
                                                                            <div style={{float: 'left', padding: '0px 0px 0px 10px '}}>
                                                                                <Button
                                                                                    onClick={() => history.push(`/invoice/${it.flightId}/tbilisi_1`)}
                                                                                    type="button" color="primary"
                                                                                    style={{width: '100%', height: '34px'}}
                                                                                    className="btn-sm waves-effect waves-light">
                                                                                    {props.t("_INVOICE_")}
                                                                                    {/*<i>{props.t('_TBILISI_')}</i>*/}
                                                                                </Button>
                                                                            </div>
                                                                        }
                                                                     {/*   {
                                                                            it.kutaisi  &&
                                                                            <div style={{float: 'left', padding: '0px 0px 0px 10px '}}>
                                                                                <Button
                                                                                    onClick={() => history.push(`/invoice/${it.flightId}/kutaisi_1`)}
                                                                                    type="button" color="primary"
                                                                                    style={{width: '100%', height: '34px'}}
                                                                                    className="btn-sm waves-effect waves-light">
                                                                                    {props.t("_INVOICE_")} <i>{props.t('_KUTAISI_')} </i>
                                                                                </Button>
                                                                            </div>
                                                                        }*/}

                                                                        {
                                                                            // it.state !== 'paid' &&
                                                                            hasUnpaid
                                                                                ?
                                                                                <div style={{float: 'left', padding: '0px 0px 0px 10px '}}>
                                                                                    <Button
                                                                                        onClick={handlePayFlight.bind(this, it.flightId)}
                                                                                        type="button" color="success"
                                                                                        style={{width: '100%', height: '34px'}}
                                                                                        className="btn-sm waves-effect waves-light">
                                                                                        {props.t("_PAY_")}
                                                                                    </Button>
                                                                                </div>
                                                                                : null
                                                                        }

                                                                    </tr>

                                                                </>

                                                                :
                                                                <tr colSpan={8} style={{padding: '0px'}}>
                                                                     <td style={{float: 'left', padding: '0px 0px 0px 10px'}}>
                                                                        <Badge
                                                                            className={`badge bg-pill font-size-12 bg-soft-primary`}
                                                                            pill
                                                                        >
                                                                            <h6 style={{margin: '0px'}}> {props.t("_FLIGHT_")}  - {it.flightId}
                                                                            </h6>
                                                                        </Badge>
                                                                    </td>

                                                                </tr>

                                                        }

                                                        {
                                                            it.custom &&  <i style={{marginLeft:'10px', color:'red'}}>{props.t('_PARCEL_FLIGHT_CUSTOM_CLERANCE_')}</i>
                                                        }

                                                        {
                                                            props.Products.array.map((item, key) => {
                                                                return (
                                                                    <Fragment key={key}>
                                                                        {
                                                                            props.country === item.senderCountryId && item.flightId === it.flightId
                                                                                ?
                                                                                <tr
                                                                                    title={item.custom === true || item.clearance === true ? props.t('_PARCEL_CUSTOM_CLERANCE_') : null}
                                                                                    style={item.custom === true || item.clearance === true ? {backgroundColor: "#fff2f2"} : {}}>
                                                                                    <td>{item.productOrderId} </td>
                                                                                    <td>{props.t(item.productName)} </td>
                                                                                    <td>
                                                                                        {item.quantity}
                                                                                    </td>

                                                                                    <td>
                                                                                        {
                                                                                            item.personalParcel
                                                                                                ? <>
                                                                                                    პერსონალური - <br/>
                                                                                                    {item.senderName}
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        item.shop === '_OTHER_SHOP_'
                                                                                                            ? item.otherShop
                                                                                                            : item.shop
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.price}
                                                                                    </td>
                                                                                    <td>
                                                                                        {item.currency}
                                                                                    </td>
                                                                                    <td>
                                                                                        {BranchAddress(item.branch,props)}
                                                                                    </td>
                                                                                    <td>

                                                                                        {
                                                                                            item.file
                                                                                                ?
                                                                                                <Button
                                                                                                    onClick={handleLink.bind(this, item.file)}
                                                                                                    type="button" color="success"
                                                                                                    style={{width: '100%'}}
                                                                                                    className="btn-sm waves-effect waves-light">
                                                                                                    {props.t("_FILE_")}
                                                                                                </Button>
                                                                                                : null
                                                                                        }

                                                                                        {
                                                                                            item.description
                                                                                                ?
                                                                                                <>
                                                                                                    <div
                                                                                                        style={{paddingTop: '5px'}}>
                                                                                                        <p data-tip={item.description}>
                                                                                                            <Button type="button"
                                                                                                                    color="primary"
                                                                                                                    style={{width: '100%'}}
                                                                                                                    className="btn-sm waves-effect waves-light">
                                                                                                                {props.t("_COMMENT_")}
                                                                                                            </Button>
                                                                                                        </p>
                                                                                                        <ReactTooltip/>
                                                                                                    </div>
                                                                                                </>
                                                                                                : null
                                                                                        }
                                                                                    </td>
                                                                                    <td>

                                                                                        {
                                                                                            props.status === 'arrived' || props.status === 'obtained'
                                                                                                ? null
                                                                                                :
                                                                                                <>
                                                                                                    {
                                                                                                        it.updateStatus
                                                                                                            ?
                                                                                                            <>
                                                                                                                <Button
                                                                                                                    onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                    type="button"
                                                                                                                    // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                    color={
                                                                                                                        it.updateStatus
                                                                                                                            ? item.quantity ? 'warning' : 'danger'
                                                                                                                            : 'danger'
                                                                                                                    }
                                                                                                                    className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                    {
                                                                                                                        it.updateStatus
                                                                                                                            ? <>
                                                                                                                                {
                                                                                                                                    item.quantity
                                                                                                                                        ? <> {props.t("_EDIT_")}</>
                                                                                                                                        : <> {props.t("_DECLARE_")}</>
                                                                                                                                }
                                                                                                                            </>
                                                                                                                            : null
                                                                                                                    }
                                                                                                                </Button>

                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    item.quantity && item.price && item.quantity
                                                                                                                        ? null
                                                                                                                        :
                                                                                                                        <Button
                                                                                                                            onClick={handleUpdateParcel.bind(this, item._id)}
                                                                                                                            type="button"
                                                                                                                            // color={item.quantity ? 'warning' : 'danger'}
                                                                                                                            color={
                                                                                                                                it.updateStatus
                                                                                                                                    ? item.quantity ? 'warning' : 'danger'
                                                                                                                                    : 'danger'
                                                                                                                            }
                                                                                                                            className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">
                                                                                                                            {props.t("_DECLARE_")}
                                                                                                                        </Button>
                                                                                                                }
                                                                                                            </>
                                                                                                    }
                                                                                                </>
                                                                                        }

                                                                                    </td>
                                                                                </tr>
                                                                                : null

                                                                        }
                                                                    </Fragment>
                                                                )
                                                            })
                                                        }

                                                    </Fragment>
                                                )
                                            })
                                    }
                                </>
                                : null

                        }


                    </>
            }


        </>

    )
}


const mapStateToProps = state => {
    const {
        Layout,
        Products
    } = state
    return {
        Layout,
        Products
    }
};

export default connect(mapStateToProps)(withTranslation()(TableObject));
