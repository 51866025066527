import {connect, useDispatch} from "react-redux";
import React, {useRef, useEffect} from "react"
import { Link } from "react-router-dom"
// //Import Scrollbar
import SimpleBar from "simplebar-react"
import {withTranslation} from "react-i18next"

import NotSignInForm from "../Authentication/NotSignInForm";
import SignInForm from "../Authentication/SignInForm";
import {Card, CardBody,} from "reactstrap";
import logoLight from "../../assets/images/logo.png";
import {checkUserAuth} from "../../store/auth/actions";
import PlayStore from "../../assets/images/PlayStore.png";
import AppStore from "../../assets/images/AppStore.png";

const SidebarContent = props => {
    const ref = useRef();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkUserAuth())
    }, [])

    return (
        <React.Fragment>

            <SimpleBar style={{maxHeight: "100%"}} ref={ref} className="sidebar-menu-scroll">
                <div id="sidebar-menu">
                    <ul className="metismenu list-unstyled" id="side-menu"></ul>

                    <Card style={{paddingTop: '0px'}}>
                        <CardBody style={{backgroundColor: '#151B21', paddingTop: '0px'}}>
                            <div className="text-center mt-1">
                                <Link to={'/'}>
                                    <img className={'logo-new'} src={logoLight} alt="" height=""/>
                                </Link>
                                <br/>
                            </div>

                            {
                                props.Auth.isAuthenticated !== null
                                    ?
                                    <>
                                        {
                                            props.Auth.isAuthenticated === false
                                                ? <NotSignInForm/>
                                                : <SignInForm/>
                                        }

                                    </>
                                    : null
                            }
                            <div className="mt-4  text-center">
                                <h5 className="font-size-14 mb-3">
                                    <hr/>
                                </h5>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <Link to={'/appstore'} >
                                            <img
                                                src={PlayStore}
                                            />
                                        </Link>
                                    </div>
                                    <div className={'col'}>
                                        <Link to={'/appstore'} >
                                            <img
                                                src={AppStore}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 text-center"  style={{
                                padding: '15px',
                                bottom: '-60px',
                                width: '100%',
                                color: 'white',
                                position: 'relative'
                            }}     >
                                <div className={'row p-2'} style={{marginBottom: '30px', padding: '2px'}}>
                                    <div className={'col-3'}></div>
                                    <div className={'col-6'}>
                                        <div className={'row'} style={{fontSize: '32px'}}>
                                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                               <a href='https://www.facebook.com/AliGeorgia.ge/'
                                                  style={{color :'white'}}
                                                  target='_blank'> <i className="bx bxl-facebook-circle"></i> </a>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                                <a href='https://www.instagram.com/aligeorgia.ge/'
                                                   style={{color :'white'}}
                                                   target='_blank'> <i className="bx bxl-instagram-alt"></i> </a>

                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                                <a href='https://www.linkedin.com/company/aligeorgia/about/'
                                                   style={{color :'white'}}
                                                   target='_blank'> <i className="bx bxl-linkedin-square"></i> </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'col-3'}></div>
                                    <div className={'col'}>
                                        <p className="pt-2"><Link to={'/terms'} style={{color: 'white'}}>
                                       {props.t("_SITE_TERMS_AND_CONDITIONS_")}  </Link>
                                        </p>
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={'col'}>
                                        <p>{props.t("_ALL_RIGHTS_RESERVED_")} © AliGeorgia.Ge 2015</p>
                                    </div>
                                </div>

                            </div>
                        </CardBody>

                    </Card>



                </div>
            </SimpleBar>
        </React.Fragment>
    )
}
// export default withRouter(withTranslation()(SidebarContent))
const mapStateToProps = state => {
    const {
        Layout,
        Auth
    } = state
    return {
        Layout,
        Auth
    }
};

export default connect(mapStateToProps)(withTranslation()(SidebarContent));
