import React, {useEffect, useState} from "react"
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container, Modal, Nav, NavItem, NavLink,
    Row, TabContent, TabPane,
} from "reactstrap"

import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Tariffs from "../../components/Tariffs";
import WeightCalculator from "../../components/WeightCalculator";
import Schedule from "../../components/Schedule";
import News from "../../components/News";
import Rates from "../../components/Rates";
import classnames from "classnames";
import Offices from "../../components/Offices/Offices";
import OfficesMob from "../../components/Offices/OfficesMob";
import TableObjects from "../../components/Logistic/TableObject";
import {
    parcelStatusUpdate,
    showRightSidebarAddParcelAction,
    showRightSidebarCourierAction
} from "../../store/layout/actions";

import {getProductInfiniti, getProducts, productNull} from "../../store/products/actions";
import NotSignInMobForm from "../../components/Authentication/NotSignInMobForm";

import TableObjectObtained from "../../components/Logistic/TableObjectObtained";
import img1 from "../../assets/images/icons/1.png";
import img4 from "../../assets/images/icons/4.png";
import img6 from "../../assets/images/icons/6.png";
import AuthHeader from "../../components/home/AuthHeader";

const Home = (props) => {
    const dispatch = useDispatch();
    const [activeTabJustify2, setactiveTabJustify2] = useState()
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100)
    const [skipObt, setSkipObt] = useState(0)
    const [limitObt, setLimitObt] = useState(30)
    const [status, setStatus] = useState('waiting')

    function toggleCustomJustified2(tab) {
        dispatch(productNull())
        if (activeTabJustify2 !== tab) {
            props.history.push('#' + tab)

            let status
            if (props.history.location.hash.replace('#', '')) {
                status = props.history.location.hash.replace('#', '')
                setStatus(status)
            } else {
                status = 'waiting'
                setStatus('waiting')
            }
            let item
            if (status === 'obtained') {
                item = {
                    status: status,
                    skip: skipObt,
                    limit: limitObt
                }

            } else {
                item = {
                    status: status,
                    skip: skip,
                    limit: limit
                }
            }


            props.dispatch(parcelStatusUpdate(tab))
            props.dispatch(getProducts(item))
            setactiveTabJustify2(status)
        }
    }

    const handleMore = () => {
        if (status === 'obtained') {
           if (props.Products.arrayObtainet.length < props.Products.productsCountObtainet) {
               let item = {
                   status: status,
                   skip: props.Products.arrayObtainet.length + limitObt,
                   limit: limitObt
               }
               if (props.Products.arrayObtainet.length === 30) {
                   item = {
                       status: status,
                       skip: limitObt,
                       limit: limitObt
                   }
               }

                dispatch(getProductInfiniti(item))
            }
        } else {
            if (props.Products.array.length < props.Products.productsCount) {
                let item = {
                    status: status,
                    skip: props.Products.array.length + limit,
                    limit: limit
                }
                dispatch(getProductInfiniti(item))
            }

        }
    }

    useEffect(() => {
        dispatch(productNull())
        let status
        if (props.history.location.hash.replace('#', '')) {
            status = props.history.location.hash.replace('#', '')
            setStatus(status)
        } else {
            status = 'waiting'
            setStatus('waiting')
        }

        let item = {
            status: status,
            skip: skip,
            limit: limit
        }

        if (props.Auth.isAuthenticated !== null) {
            if (props.Auth.isAuthenticated === true) {

                let item
                if (status === 'obtained') {
                    item = {
                        status: status,
                        skip: skipObt,
                        limit: limitObt
                    }

                } else {
                    item = {
                        status: status,
                        skip: skip,
                        limit: limit
                    }
                }

                props.dispatch(getProducts(item))
            }
        }
        setactiveTabJustify2(status)
    }, [props.Auth.isAuthenticated])

    const handleAddParcel = () => {
        dispatch(showRightSidebarAddParcelAction(true))
    }

    const handleCourirer = () => {
        dispatch(showRightSidebarCourierAction(true))
    }

    const [modal_currency, setmodal_currency] = useState(false)
    const [modal_callculator, setmodal_callculator] = useState(false)

    function tog_currency() {
        setmodal_currency(!modal_currency)
    }

    function tog_callculator() {
        setmodal_callculator(!modal_callculator)
    }


    return (
        <React.Fragment>
            {
                props.Auth.isAuthenticated !== null
                    ?
                    <>
                        {
                            props.Auth.isAuthenticated === false
                                ?
                                <>
                                    <div className="page-content-2" style={{paddingTop: '60px'}}>
                                        <Container fluid>
                                            <Row>
                                                <Col md={12} style={{backgroundColor: '#151B21'}}>
                                                    <NotSignInMobForm/>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="page-content-index ">
                                        <Container fluid className='service-mob'>
                                            <Row style={{textAlign: 'center'}}>
                                                <Col md={4}>
                                                    <Card className="mt-4 maintenance-box">
                                                        <CardBody className="p-4" style={{minHeight: '300px'}}>
                                                            <div className="avatar-sm mx-auto mb-4">
                                                                <img style={{height: '60px'}} src={img4}/>
                                                            </div>
                                                            <h5 className="font-size-15 text-uppercase">
                                                                <strong> {props.t('_BIG_TEXT_SERVICE_4_TITLE')}</strong>
                                                            </h5>
                                                            <p className="text-muted mb-0"
                                                               style={{textAlign: 'left', whiteSpace: 'pre-wrap'}}>{props.t('_BIG_TEXT_SERVICE_4')}</p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col md={4}>
                                                    <Card className="mt-4 maintenance-box">
                                                        <CardBody className="p-4" style={{minHeight: '300px'}}>
                                                            <div className="avatar-sm mx-auto mb-4">
                                                                <img style={{height: '60px'}} src={img1}/>
                                                            </div>
                                                            <h5 className="font-size-15 text-uppercase">
                                                                <strong> {props.t('_BIG_TEXT_SERVICE_3_TITLE')}</strong>
                                                            </h5>
                                                            <p className="text-muted mb-0"
                                                               style={{textAlign: 'left'}}>   {props.t('_BIG_TEXT_SERVICE_3')}</p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col md={4}>
                                                    <Card className="mt-4 maintenance-box">
                                                        <CardBody className="p-4" style={{minHeight: '300px'}}>
                                                            <div className="avatar-sm mx-auto mb-4">
                                                                <img style={{height: '60px'}} src={img6}/>
                                                            </div>
                                                            <h5 className="font-size-15 text-uppercase">
                                                                <strong> {props.t('_BIG_TEXT_SERVICE_6_TITLE')}</strong>
                                                            </h5>
                                                            <p className="text-muted mb-0" style={{
                                                                textAlign: 'left',
                                                                whiteSpace: 'pre-wrap'
                                                            }}>{props.t('_BIG_TEXT_SERVICE_6')}</p>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>

                                        <Container fluid>
                                            <Row>
                                                <Col md={8}>
                                                    <Schedule/>
                                                </Col>

                                                <Col md={4}>
                                                    <Tariffs/>
                                                    <Rates type={true}/>
                                                    <WeightCalculator type={true}/>

                                                </Col>
                                            </Row>

                                        </Container>

                                        <News/>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="page-content">
                                        <AuthHeader/>
                                        <Offices/>
                                        <OfficesMob/>
                                        <Container fluid>
                                            <Row>
                                                <Col md={12}>
                                                    <Button
                                                        onClick={handleAddParcel}
                                                        className="btn btn-lg btn-success waves-effect waves-light">
                                                        <i className="uil uil-plus"></i>{" "}
                                                        {props.t("_ADD_PARCEL_")}
                                                    </Button>
                                                    <Button
                                                        onClick={handleCourirer}
                                                        className="btn btn-lg btn-warning waves-effect waves-light btn-mini">
                                                        <i className="uil uil-plus"></i>{" "}
                                                        {/*{props.t("საკურიერო მომსახურეობა")}*/}
                                                        {props.t("_DELIVERY_SERVICE_")}
                                                    </Button>
                                                    <Button

                                                        onClick={() => {
                                                            tog_currency()
                                                        }}
                                                        className="btn btn-lg btn-info waves-effect waves-light btn-mini">
                                                        <i className="uil uil-dollar-sign-alt"></i>
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            tog_callculator()
                                                        }}
                                                        className="btn btn-lg btn-warning waves-effect waves-light btn-mini">
                                                        <i className="uil uil-calculator"></i>
                                                    </Button>
                                                </Col>
                                            </Row>

                                            <Modal
                                                isOpen={modal_currency}
                                                toggle={() => {
                                                    tog_currency()
                                                }}
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                                        {props.t("_CURRENCY_CONVERTER_")}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setmodal_currency(false)
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <Rates
                                                        type={false}
                                                    />
                                                </div>
                                            </Modal>

                                            <Modal
                                                isOpen={modal_callculator}
                                                toggle={() => {
                                                    tog_callculator()
                                                }}
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                                        {props.t("_VOLUME_WEIGHT_CALCULATOR_")}
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setmodal_callculator(false)
                                                        }}
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <WeightCalculator
                                                        type={false}
                                                    />
                                                </div>
                                            </Modal>

                                            <Row style={{paddingTop: '10px',}}>
                                                <Col md={12}>
                                                    <Alert color="danger" style={{margin: '0px', textAlign: 'center'}}>
                                                        {props.t("_PLEASE_NOTE_BIG_TEXT_")}
                                                    </Alert>
                                                </Col>
                                            </Row>

                                            <Row style={{paddingTop: '20px'}}>
                                                <Col xl={12}>
                                                    <Card>
                                                        <CardBody>
                                                            <Nav pills className="nav-justified bg-light">
                                                                <NavItem className="waves-effect waves-light">
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            active: activeTabJustify2 === "waiting",
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleCustomJustified2("waiting")
                                                                        }}
                                                                    >
                                                                        <span className="d-block d-sm-none"><i
                                                                            className="fas fa-mail-bulk"></i></span>
                                                                        <span
                                                                            className="d-none d-sm-block">{props.t("_WAITING_PARCELS_")}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem className="waves-effect waves-light">
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            active: activeTabJustify2 === "stock",
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleCustomJustified2("stock")
                                                                        }}
                                                                    >
                                                                        <span className="d-block d-sm-none"><i
                                                                            className="fas fa-plane"></i></span>
                                                                        <span
                                                                            className="d-none d-sm-block">{props.t("_DELIVERED_")}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem className="waves-effect waves-light">
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            active: activeTabJustify2 === "sent",
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleCustomJustified2("sent")
                                                                        }}
                                                                    >
                                                                        <span className="d-block d-sm-none"><i
                                                                            className="fas fa-plane-departure"></i></span>
                                                                        <span
                                                                            className="d-none d-sm-block">{props.t("_SENT_")}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem className="waves-effect waves-light">
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            active: activeTabJustify2 === "arrived",
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleCustomJustified2("arrived")
                                                                        }}
                                                                    >
                                                                        <span className="d-block d-sm-none"><i
                                                                            className="fas fa-plane-arrival"></i></span>
                                                                        <span
                                                                            className="d-none d-sm-block">{props.t("_ARRIVED_")}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem className="waves-effect waves-light">
                                                                    <NavLink
                                                                        style={{cursor: "pointer"}}
                                                                        className={classnames({
                                                                            active: activeTabJustify2 === "obtained",
                                                                        })}
                                                                        onClick={() => {
                                                                            toggleCustomJustified2("obtained")
                                                                        }}
                                                                    >
                                                                        <span className="d-block d-sm-none"><i
                                                                            className="fas fa-check-square"></i></span>
                                                                        <span
                                                                            className="d-none d-sm-block">{props.t("_RECEIVED_")}</span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>

                                                            <TabContent activeTab={activeTabJustify2}
                                                                        className="text-muted"
                                                                        style={{paddingTop: '10px'}}>
                                                                <TabPane tabId="waiting">
                                                                    {
                                                                        activeTabJustify2 === 'waiting'
                                                                            ?
                                                                            <TableObjects
                                                                                handleMore={handleMore}
                                                                                status={'waiting'}
                                                                                text={props.t("_YOU_DON_T_HAVE_A_PARCEL_")}
                                                                            />
                                                                            : null
                                                                    }

                                                                </TabPane>
                                                                <TabPane tabId="stock">
                                                                    {
                                                                        activeTabJustify2 === 'stock'
                                                                            ?
                                                                            <TableObjects
                                                                                handleMore={handleMore}
                                                                                status={'stock'}
                                                                                text={props.t("_YOU_DON_T_HAVE_A_PARCEL_")}
                                                                            />
                                                                            : null
                                                                    }

                                                                </TabPane>
                                                                <TabPane tabId="sent">
                                                                    {
                                                                        activeTabJustify2 === 'sent'
                                                                            ?
                                                                            <TableObjects
                                                                                handleMore={handleMore}
                                                                                status={'sent'}
                                                                                text={props.t("_YOU_DON_T_HAVE_A_PARCEL_")}
                                                                            />
                                                                            : null
                                                                    }

                                                                </TabPane>

                                                                <TabPane tabId="arrived">
                                                                    {
                                                                        activeTabJustify2 === 'arrived'
                                                                            ?
                                                                            <TableObjects
                                                                                handleMore={handleMore}
                                                                                status={'arrived'}
                                                                                text={props.t("_YOU_DON_T_HAVE_A_PARCEL_")}
                                                                            />
                                                                            : null
                                                                    }

                                                                </TabPane>
                                                                <TabPane tabId="obtained">
                                                                    {
                                                                        activeTabJustify2 === 'obtained'
                                                                            ?
                                                                            <TableObjectObtained
                                                                                handleMore={handleMore}
                                                                                status={'obtained'}
                                                                                text={props.t("_YOU_DON_T_HAVE_A_PARCEL_")}
                                                                            />
                                                                            : null
                                                                    }

                                                                </TabPane>
                                                            </TabContent>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>

                                        </Container>
                                    </div>

                                </>
                        }

                    </>
                    : null
            }

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const {
        Auth,
        Layout,
        Products
    } = state
    return {
        Auth,
        Layout,
        Products
    }
};

export default connect(mapStateToProps)(withTranslation()(Home));
